<template>
  <land-section
    id="my_upload"
    space="40"
    color="grey lighten-4"
  >
    <v-container
      style="max-width: 1200px;"
    >
      <v-row>
        <v-col
          cols="12"
          md="8"
          sm="12"
        >
          <v-card
            color="white"
            flat
            tile
            height="700"
            class="overflow-y-auto overflow-x-hidden rounded px-6 py-12"
          >
            <upload-images
              ref="wdUpload"
              :config="upConfig"
              @change="onUploadImagesChange"
            />
          </v-card>

          <div class="co-flex-row co-justify-between co-items-center co-w-full pa-2">
            <span class="blue-grey--text">
              {{ imgTip }}
            </span>
            <span class="blue-grey--text">
              {{ `${imgCount} / ${upConfig.limit}` }}
            </span>
            <!-- <span
              v-if="upImages.length"
              class="blue-grey--text"
            >
              {{ upImages.length }}
            </span> -->
          </div>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="12"
        >
          <v-card
            class="rounded px-4 py-6"
            color="white"
            flat
            tile
            height="700"
          >
            <div class="co-flex-col co-justify-between co-h-full">
              <v-form
                v-if="frmUpload.state === 'issue'"
                :ref="frmUpload.name"
                v-model="frmUpload.valid"
                lazy-validation
              >
                <v-btn
                  :loading="btnSubmit.loading"
                  dark
                  width="100%"
                  height="48"
                  color="primary"
                  class="mb-8"
                  @click="onSubmit"
                >
                  {{ btnSubmit.text }}
                </v-btn>

                <v-textarea
                  v-model="txtTitle.value"
                  :rules="txtTitle.rules"
                  :label="txtTitle.label"
                  counter
                  :maxlength="txtTitle.maxlength"
                  :hint="txtTitle.hint"
                  persistent-hint
                  outlined
                  required
                  rows="2"
                />

                <v-textarea
                  v-model="txtDesc.value"
                  :label="txtDesc.label"
                  outlined
                />
                <v-select
                  v-model="cmbScope.value"
                  :label="cmbScope.label"
                  :items="cmbScope.items"
                  outlined
                  return-object
                  item-text="text"
                  item-value="value"
                />
              </v-form>
              <div
                v-else
                class="co-flex-col co-justify-center co-items-center py-10"
              >
                <v-icon
                  size="160"
                  :color="resultTip.color || 'grey'"
                >
                  {{ resultTip.icon }}
                </v-icon>
                <span
                  class="co-text-md blue-grey--text lighten-2 mt-4"
                >
                  {{ resultTip.hint }}
                </span>
                <v-btn
                  dark
                  width="180"
                  height="40"
                  :color="resultTip.color"
                  class="mt-8"
                  @click="onNext"
                >
                  {{ resultTip.next }}
                </v-btn>
              </div>

              <div class="co-flex-row co-justify-between co-items-center co-w-full">
                <v-btn
                  width="46%"
                  outlined
                  color="blue"
                  @click="toIssueVideo"
                >
                  {{ btnVideo.text }}
                </v-btn>
                <v-btn
                  width="46%"
                  outlined
                  color="indigo"
                  @click="toIssueArticle"
                >
                  {{ btnArticle.text }}
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <dialog-alert ref="dlgAlert" />
  </land-section>
</template>

<script>
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import user from '@/api/co.user'

  export default {
    metaInfo: { title: '上传' },
    components: {
      UploadImages: () => import('@/pages/sections/my/UploadImages.vue'),
      DialogAlert: () => import('@/pages/sections/comm/DialogAlert.vue')
    },
    data () {
      return {
        userInfo: {},
        upConfig: {
          ratio: 1.45,
          numberPerRow: 3,
          limit: 9
        },
        imgCount: 0,
        imgTip: '只能上传jpg/png/gif文件，且每个文件不能超过20MB',
        btnSubmit: {
          loading: false,
          text: '发表图片'
        },
        tips: {
          success: {
            value: 'success',
            icon: 'mdi-check-circle-outline',
            color: 'success',
            hint: '图片发表成功',
            next: '继续发表',
            handler: this.toContinueIssue
          },
          error: {
            value: 'error',
            icon: 'mdi-close-circle-outline',
            color: 'error',
            hint: '图片发表失败',
            next: '重新提交',
            handler: this.toReissue
          }
        },
        resultTip: {},
        btnVideo: {
          text: '去发表视频'
        },
        btnArticle: {
          text: '去发表文章'
        },
        txtTitle: {
          value: '',
          label: '标题',
          hint: '本次发布作品的标题',
          maxlength: 64,
          rules: [
            v => !!v || '必须填写标题',
            v => (v && v.length <= 64) || '标题不能超过 64 个字符',
          ]
        },
        txtDesc: {
          value: '',
          label: '描述'
        },
        cmbScope: {
          value: {},
          label: '可见范围',
          items: []
        },
        upImages: [],
        frmUpload: {
          name: 'form_upload',
          valid: true,
          state: 'issue'
        },
      }
    },
    created () {
      this.userInfo = user.info.get()
      this.configScopes()
    },
    methods: {
      showAlert (type, message) {
        const dlg = this.$refs.dlgAlert
        if (dlg) {
          dlg.show({
            type,
            message
          })
        }
      },
      toIssueVideo () {
        api.page.navigate(this, 'issueVideo')
      },
      toIssueArticle () {
        api.page.navigate(this, 'issueArticle')
      },
      uploadFiles () {
        const frm = this.$refs.wdUpload
        if (frm) {
          frm.submit()
        }
      },
      resetForm () {
        const frm = this.$refs[this.frmUpload.name]
        if (frm) {
          frm.reset()
        }
      },
      checkWidget () {
        const result = []
        const frm = this.$refs[this.frmUpload.name]
        if (!frm) {
          return
        }
        if (!frm.validate()) {
          result.push('填写发布图片标题')
        }

        if (this.upImages.length < 1) {
          result.push('上传需要发表的图片')
        }

        if (result.length) {
          return result.join('，')
        } else {
          return ''
        }
      },
      setResultTip (val) {
        const tip = this.tips[val] || {}
        this.resultTip = tip
        this.frmUpload.state = tip.value || 'issue'
      },
      toContinueIssue () {
        this.setResultTip('issue')
        let frm = this.$refs[this.frmUpload.name]
        if (frm) {
          frm.reset()
        }

        frm = this.$refs.wdUpload
        if (frm) {
          frm.clear()
        }
        this.upImages = []
      },
      toReissue () {
        const result = this.checkUploading()
        if (result.ready || result.error) {
          this.uploadFiles()
        } else {
          this.issueContent()
        }
      },
      onNext () {
        const handler = this.resultTip.handler
        if (typeof handler === 'function') {
          handler()
        }
      },
      onSubmit () {
        if (!this.userInfo.mobile) {
          const me = this
          this.$confirm(
            '需要完善个人信息后才能发表作品。',
            '信息完善提示', {
              distinguishCancelAndClose: true,
              confirmButtonText: '去完善',
              cancelButtonText: '暂不'
            }
          )
            .then(() => {
              api.page.navigate(me, 'certificate')
            })
            .catch(action => {})
        } else {
          const message = this.checkWidget()
          if (message) {
            this.showAlert('warning', '必须上传图片或填写标题')
          } else {
            this.btnSubmit.loading = true
            this.uploadFiles()
          }
        }
      },
      getData () {
        const thumbs = []

        for (const i in this.upImages) {
          const obj = this.upImages[i]
          const resp = obj.response || {}
          const data = resp.data || {}
          if (data.fileUrl) {
            thumbs.push(data.fileUrl)
          }
        }

        const cover = thumbs[0]
        const scope = this.cmbScope.value
        const result = {
          title: this.txtTitle.value,
          description: this.txtDesc.value,
          cover,
          type: web.comm.ContentTypes.IMAGE,
          state: web.comm.States.PUBLISH,
          accessScope: scope.value || web.comm.Scopes.PUBLIC,
          thumbs: JSON.stringify(thumbs)
        }

        return result
      },
      issueContent () {
        const me = this
        const data = this.getData()
        const executed = function (res) {
          me.btnSubmit.loading = false
          if (res.status) {
            me.setResultTip('success')
            me.showAlert('success', '图片发表成功')
          } else {
            me.setResultTip('error')
            const err = res.data || {}
            me.showAlert('error', `${err.code}-${err.desc}`)
          }
        }

        web.content.create({
          data,
          executed
        })
      },
      checkUploading () {
        const total = this.upImages.length
        let ready = 0
        let success = 0
        let error = 0
        for (const i in this.upImages) {
          const src = this.upImages[i]
          if (src.status === 'success') {
            success += 1
          } else if (src.status === 'ready') {
            ready += 1
          } else {
            error += 1
          }
        }

        return {
          ready, success, error, total
        }
      },
      checkIssue () {
        const result = this.checkUploading()
        if (result.ready < 1) {
          this.issueContent()
        }
      },
      configScopes () {
        this.cmbScope.items = []
        const descs = web.comm.ScopeDescs
        for (const i in descs) {
          const desc = descs[i]
          this.cmbScope.items.push({
            text: desc.text,
            value: desc.value
          })
        }

        this.cmbScope.value = this.cmbScope.items[0]
      },
      onUploadImagesChange (ev) {
        // console.log({ ev })
        this.imgCount = ev.count
        this.upImages = ev.items

        this.checkIssue()
      },
    }
  }
</script>
